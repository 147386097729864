import React, { CSSProperties } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { SQUARE_SIZE_PX } from './common';

const itemStyle = {
  boxSizing: 'border-box',
  height: `${SQUARE_SIZE_PX}px`,
  width: `${SQUARE_SIZE_PX}px`,
  backgroundColor: 'white',
  color: 'black',
} as CSSProperties;

export interface TierItemState {
  draggableId: string,
  imageUrl?: string
}

export interface TierItemProps extends TierItemState {
  index: number,
}

export function TierItem({ draggableId, imageUrl, index }: TierItemProps) {
  return (
    <Draggable index={index} draggableId={draggableId}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {imageUrl && <img style={itemStyle} src={imageUrl} alt={draggableId} />}
          {!imageUrl && (
            <div style={{
              ...itemStyle,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              <p style={{ margin: 0 }}>{draggableId}</p>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
}

export function TierItems({ tierItemStates }: { tierItemStates: TierItemState[] }) {
  return (
    <>
      {
      tierItemStates
        && tierItemStates.map((itemState, ind) => (
          <TierItem index={ind} key={itemState.draggableId} {...itemState} />
        ))
    }
    </>
  );
}

export const MemoTierItems = React.memo(TierItems);
