export type TierId = string;

export enum DroppableType {
  Tier = 'tier',
  Item = 'item',
}

export enum TierColours {
  Red = 'rgb(255, 127, 127)',
  Orange = 'rgb(255, 191, 127)',
  LightOrange = 'rgb(255, 223, 127)',
  Yellow = 'rgb(255, 255, 127)',
  Lime = 'rgb(191, 255, 127)',
  Green = 'rgb(127, 255, 127)',
  Cyan = 'rgb(127, 255, 255)',
}

export const DELETE_DROPPABLE_ID_PREFIX = '_DELETE';

export const SQUARE_SIZE_PX = 96;
