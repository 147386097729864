import React, {
  CSSProperties, useCallback, useState,
} from 'react';
import { useTransition, animated } from '@react-spring/web';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { CirclePicker } from 'react-color';
import { TierColours } from './common';
// import { TierColours } from './common';

const baseStyle = {
  backgroundColor: 'darkgrey',
  overflow: 'hidden',
  position: 'relative',
} as CSSProperties;

export default function TierAccordion(props: {
  open: boolean,
  initialTierName: string,
  onChangeTierName: (e: React.ChangeEvent<HTMLInputElement>) => void
  onColourChange: (colour: string) => void
  onDeleteClick: React.MouseEventHandler<HTMLButtonElement>
}) {
  const {
    open,
    initialTierName,
    onChangeTierName,
    onColourChange,
    onDeleteClick,
  } = props;

  const [height, setHeight] = useState(0);

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const transitions = useTransition(open, {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height },
    leave: { opacity: 0, height: 0 },
    update: { height },
    config: { duration: 400 },
  });

  return transitions((styles, isOpen) => (isOpen ? (
    <animated.div style={{ ...styles, ...baseStyle }}>
      <div ref={measuredRef}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <input
            type="text"
            onChange={onChangeTierName}
            defaultValue={initialTierName}
          />
          <button style={{ color: 'white', backgroundColor: 'red' }} type="button" onClick={onDeleteClick}>Delete this tier</button>
        </div>
        <p>Colour Picker</p>
        <CirclePicker
          colors={Object.values(TierColours)}
          onChangeComplete={(colourResult) => onColourChange(colourResult.hex)}
          width="100%"
        />
      </div>
    </animated.div>
  ) : (<></>)));
}
