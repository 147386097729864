export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = [...list];
  const [itemToMove] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, itemToMove);
  return result;
};

export const moveItem = (
  sourceList: any[],
  destList: any[],
  sourceIndex: number,
  destinationIndex: number,
) => {
  const sourceListCopy = sourceList ? [...sourceList] : [];
  const destListCopy = destList ? [...destList] : [];

  const [itemToMove] = sourceListCopy.splice(sourceIndex, 1);
  destListCopy.splice(destinationIndex, 0, itemToMove);

  return [sourceListCopy, destListCopy];
};

export const deleteItem = (
  list: any[],
  index: number,
) => {
  const result = [...list];
  // delete the item
  result.splice(index, 1);
  return result;
};
