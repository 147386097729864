const THUMBNAIL_SIZE = 128;

export const toCroppedImage = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.onabort = () => reject(Error('file reading abborted'));
  reader.onerror = () => reject(Error('error reading file'));
  reader.onload = async () => {
    // Do whatever you want with the file contents
    const originalDataUrl = reader.result;
    if (typeof originalDataUrl === 'string') {
      const canvas = document.createElement('canvas');
      const originalImage = new Image();
      originalImage.src = originalDataUrl;
      await originalImage.decode();

      canvas.height = THUMBNAIL_SIZE;
      canvas.width = THUMBNAIL_SIZE;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(originalImage,
        0, 0,
        originalImage.naturalWidth, originalImage.naturalHeight,
        0, 0,
        THUMBNAIL_SIZE, THUMBNAIL_SIZE);

      const croppedDataUrl = canvas.toDataURL('image/png');

      resolve(croppedDataUrl);
    } else {
      reject(Error('could not read as dataurl'));
    }
  };
  reader.readAsDataURL(file);
});

export const setupDropzone = () => {

};
