import { CSSProperties } from 'react';
import { SQUARE_SIZE_PX } from './common';

const tierNameContainerStyle = {
  display: 'flex',
  width: `${SQUARE_SIZE_PX}px`,
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  marginRight: '4px',
} as CSSProperties;

export default function TierName(props: {
  name: string,
  colour: string,
}) {
  const {
    name,
    colour,
    ...rest
  } = props;

  return (
    <div style={{ ...tierNameContainerStyle, backgroundColor: colour }} {...rest}>
      <p style={{ margin: 0 }}>{name}</p>
    </div>
  );
}
