import React, {
  CSSProperties, useCallback, useMemo,
} from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { DELETE_DROPPABLE_ID_PREFIX, DroppableType } from './common';
import { MemoTierItems, TierItemState } from './DraggableTierItem';
import { toCroppedImage } from './ImageUtils';

export const SHELF_TIER_ID = '_SHELF';

const style = {
  flex: '1 1 auto',

  display: 'flex',
  flexWrap: 'wrap',
  backgroundColor: 'black',
  alignItems: 'alignItems',
} as CSSProperties;

const baseStyle : CSSProperties = {
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'inset',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export default function ItemShelf(props:
{
  shelfItems: TierItemState[]
  setShelfItems: (items: TierItemState[]) => void,
}) {
  const {
    shelfItems,
    setShelfItems,
    ...rest
  } = props;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const filesWithKey = acceptedFiles
      .map(async (file) => {
        const dataUrl = await toCroppedImage(file);
        const key = `${file.name}_${Date.now()}`;
        const item = {
          draggableId: key,
          imageUrl: dataUrl,
        } as TierItemState;

        return item;
      });

    if (filesWithKey) {
      Promise.all(filesWithKey)
        .then((newItems) => {
          const oldItems = shelfItems || [];
          setShelfItems([...oldItems, ...newItems]);
        });
    }
  }, [setShelfItems]);

  const dropzoneOptions = {
    accept: 'image/*',
    onDrop,
    noClick: true,
    uploadMultiple: true,
  } as DropzoneOptions;

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone(dropzoneOptions);

  const borderStyle = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
  ]);

  return (
    <div>
      <div {...getRootProps()} style={{ ...borderStyle }} {...rest}>
        <input {...getInputProps()} />
        <div style={style}>
          <Droppable
            droppableId={SHELF_TIER_ID}
            type={DroppableType.Item}
            direction="horizontal"
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                style={{ display: 'flex', flexGrow: 1, flexWrap: 'wrap' }}
                {...provided.droppableProps}
              >
                {(shelfItems.length)
                  ? (<MemoTierItems tierItemStates={shelfItems} />)
                  : (
                    <p>
                      <strong>
                        Drag n drop images here
                      </strong>
                    </p>
                  )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>

        </div>
      </div>
      <div style={borderStyle}>
        <Droppable droppableId={`${DELETE_DROPPABLE_ID_PREFIX}_bin`} type={DroppableType.Item} direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={snapshot.isDraggingOver ? {
                backgroundColor: 'red', alignItems: 'center',
              } : {}}
              {...provided.droppableProps}
            >
              <p>
                <span>
                  <svg style={{ height: '18px' }} fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z" /></svg>
                </span>
                Drag here to delete an item
              </p>
            </div>
          )}
        </Droppable>
      </div>
      <button type="button" onClick={open}>
        Upload File
      </button>
    </div>
  );
}
