import React, { useState, useEffect } from 'react';
import {
  DragDropContext, Droppable, DropResult,
} from 'react-beautiful-dnd';
import {
  DELETE_DROPPABLE_ID_PREFIX, DroppableType, TierColours, TierId,
} from './common';
import { MemoDragDroppableTier, TierState } from './DragDroppableTier';
import { TierItemState } from './DraggableTierItem';
import ItemShelf, { SHELF_TIER_ID } from './ItemShelf';
import { deleteItem, moveItem, reorder } from './TierDragNDropUtils';

const generateUniqueId = () => Date.now().toString();

const [
  LOCALSTORAGE_TIERORDER_KEY,
  LOCALSTORAGE_TIERSTATE_KEY,
  LOCALSTORAGE_TIERLISTTITLE_KEY,
] = ['TIERORDER', 'TIERSTATE', 'TIERLISTTITLE'];

const initialTierOrder = ['S', 'A', 'B', 'C', 'D', 'E', 'F'];

const initialTierStates: Record<TierId, TierState> = initialTierOrder
  .reduce((acc, id, index) => {
    const state = {
      tierName: id,
      colour: Object.values(TierColours)[index % Object.values(TierColours).length],
      tierItemStates: [],
    };
    return { ...acc, [id]: state };
  }, {});

const initialTierStatesWithShelf: Record<TierId, TierState> = {
  ...initialTierStates,
  [SHELF_TIER_ID]: {
    tierName: '_shelf', // should never be used
    tierItemStates: [],
    // Array.from(Array(10).keys()).map((_, ind) => ({ draggableId: ind.toString() })),
  },
};

export default function Tierlist(): JSX.Element {
  const [tierOrder, setTierOrder] = useState(() => {
    const maybeLs = window.localStorage.getItem(LOCALSTORAGE_TIERORDER_KEY);
    return maybeLs != null ? JSON.parse(maybeLs) as string[] : initialTierOrder;
  });
  const [tierState, setTierState] = useState(() => {
    const maybeLs = window.localStorage.getItem(LOCALSTORAGE_TIERSTATE_KEY);
    return maybeLs != null
      ? JSON.parse(maybeLs) as Record<TierId, TierState>
      : initialTierStatesWithShelf;
  });
  const [openEditTitleInput, setOpenEditTitleInput] = useState(false);
  const [tierlistTitle, setTierlistTitle] = useState(() => window.localStorage.getItem(LOCALSTORAGE_TIERLISTTITLE_KEY) || 'Tierney - the tier list maker');

  useEffect(() => {
    window.localStorage.setItem(LOCALSTORAGE_TIERORDER_KEY, JSON.stringify(tierOrder));
  }, [tierOrder]);

  useEffect(() => {
    window.localStorage.setItem(LOCALSTORAGE_TIERSTATE_KEY, JSON.stringify(tierState));
  }, [tierState]);

  useEffect(() => {
    window.localStorage.setItem(LOCALSTORAGE_TIERLISTTITLE_KEY, tierlistTitle);
  }, [tierlistTitle]);

  const getTierState = (tierId: TierId) => tierState[tierId];

  const updatedTierState = (tierId: TierId, updated: Object) => {
    const stateCopy = { ...getTierState(tierId) };
    return { ...stateCopy, ...updated } as TierState;
  };

  const setTierItems = (tierId: string, items: TierItemState[]) => {
    const nextTierState = updatedTierState(tierId, { tierItemStates: items });
    setTierState({ ...tierState, [tierId]: nextTierState });
  };

  const onTierStateChange = (tierId: TierId, newTierState: TierState) => {
    setTierState({ ...tierState, [tierId]: newTierState });
  };

  const onDeleteTier = (tierId: TierId) => {
    setTierOrder(tierOrder.filter((tier) => tier !== tierId));
    const oldTierItemStates = getTierState(tierId).tierItemStates;
    // move the items into the shelf
    const newTierState: Record<TierId, TierState> = {
      ...tierState,
      [SHELF_TIER_ID]: {
        ...getTierState(SHELF_TIER_ID),
        tierItemStates: [...getTierState(SHELF_TIER_ID).tierItemStates, ...oldTierItemStates],
      },
    };
    setTierState(newTierState);
  };

  const addTier = () => {
    const newId = generateUniqueId();
    setTierState({
      ...tierState,
      [newId]: {
        tierName: newId,
        colour: Object.values(TierColours)[0],
        tierItemStates: [],
      },
    });
    setTierOrder([...tierOrder, newId]);
  };

  const onDragEnd = (result: DropResult) => {
    const shouldReorder = result.source.droppableId === result.destination?.droppableId;
    switch (result.type) {
      case DroppableType.Tier:
      // reorder the tier
        if (result.destination && shouldReorder) {
          const reordered = reorder(
            tierOrder,
            result.source.index,
            result.destination?.index,
          );
          setTierOrder(reordered);
        }
        break;
      case DroppableType.Item:
      // reorder item
        if (result.destination?.droppableId.startsWith(DELETE_DROPPABLE_ID_PREFIX)) {
          const tierId = result.source.droppableId;
          const updated = deleteItem(getTierState(tierId).tierItemStates, result.source.index);
          setTierItems(tierId, updated);
        } else if (result.destination && shouldReorder) {
          const tierId = result.destination.droppableId;
          const reordered = reorder(
            getTierState(tierId).tierItemStates,
            result.source.index,
            result.destination.index,
          );
          setTierItems(tierId, reordered);
        // move item (droppable id for destination is different)
        } else if (result.destination && !shouldReorder) {
        // if not reorder we need to move
          const sourceTierId = result.source.droppableId;
          const destTierId = result.destination.droppableId;
          const [updatedSource, updatedDest] = moveItem(
            getTierState(sourceTierId).tierItemStates,
            getTierState(destTierId).tierItemStates,
            result.source.index,
            result.destination.index,
          );
          // don't use set tier items as we need to set two at once
          const nextSourceState = updatedTierState(sourceTierId, { tierItemStates: updatedSource });
          const nextDestState = updatedTierState(destTierId, { tierItemStates: updatedDest });
          setTierState({
            ...tierState,
            [sourceTierId]: nextSourceState,
            [destTierId]: nextDestState,
          });
        }
        break;
      // eslint-disable-next-line no-console
      default: console.error('???');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1>
          {tierlistTitle}
          <span>
            <button
              style={{
                marginLeft: '10px', height: '100%', backgroundColor: 'transparent', border: '0',
              }}
              type="button"
              onClick={() => setOpenEditTitleInput(!openEditTitleInput)}
            >
              <svg style={{ width: '9px' }} fill="white" xmlns="http://www.w2.org/2000/svg" viewBox="0 0 512 512"><path d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z" /></svg>
            </button>
          </span>
        </h1>
      </div>
      {openEditTitleInput && (<input type="text" onChange={(e) => setTierlistTitle(e.target.value)} value={tierlistTitle} />)}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={DroppableType.Tier} type={DroppableType.Tier}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {tierOrder.filter((tierId) => tierId in tierState).map((tierId, ind) => (
                <MemoDragDroppableTier
                  key={tierId}
                  index={ind}
                  tierId={tierId}
                  onChange={(state) => onTierStateChange(tierId, { ...state })}
                  onDelete={() => onDeleteTier(tierId)}
                  {...getTierState(tierId)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <button type="button" onClick={addTier}>New Tier</button>
        <h3>Items</h3>
        <ItemShelf
          shelfItems={getTierState(SHELF_TIER_ID).tierItemStates}
          setShelfItems={(items: TierItemState[]) => setTierItems(SHELF_TIER_ID, items)}
        />
      </DragDropContext>
      <button type="button" onClick={() => { window.localStorage.clear(); window.location.reload(); }}>Reset Tierlist</button>
    </>
  );
}
